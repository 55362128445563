import React from 'react'
import Layout from '../components/Layout'
import SEO from '../components/SEO'
import Landing from '../components/Landing'
import { graphql } from 'gatsby'
import { HomepageQuery } from '../../types/graphql-types'
import { Link as GatsbyLink, useIntl } from 'gatsby-plugin-intl'
import Contact from '../components/Contact'
import { Button, filterButtonProps } from '@aristech/components'
import { Container, Box } from '../components/Boxes'
import Header from '../components/Header'
import Testimonials from '../components/Testimonials'

const Link = filterButtonProps(GatsbyLink)
interface Props {
  data: HomepageQuery
}

const IndexPage: React.FC<Props> = (props: Props) => {
  const { data } = props
  const intl = useIntl()
  const [scrolled, setScrolled] = React.useState(true)

  const siteUrl = data.site!.siteMetadata!.siteUrl!

  return (
    <Layout
      menuData={(data as any).cms.mainMenu[0]}
      footerMenuData={(data as any).cms.footerMenus}
      transparentMenubar={scrolled}
      burgerMenubar={scrolled}
      languageSwitchData={(data as any).cms.languages}
    >
      <SEO
        title={intl.formatMessage({ id: 'home.title' })}
        description={intl.formatMessage({ id: 'home.description' })}
        previewImage={data.previewImage?.childImageSharp?.fixed || undefined}
        link={[
          {
            rel: 'canonical',
            href: `${siteUrl}/${intl.locale}/`,
          },
          {
            rel: 'alternate',
            hrefLang: 'en',
            href: `${siteUrl}/en/`,
          },
          {
            rel: 'alternate',
            hrefLang: 'de',
            href: `${siteUrl}/de/`,
          },
          {
            rel: 'alternate',
            hrefLang: 'x-default',
            href: `${siteUrl}/de/`,
          },
        ]}
      />

      <Landing setInView={inView => setScrolled(inView)} />

      <div style={{ paddingBottom: '3rem' }}>
        <Header id="overview" as="h1">
          {intl.formatMessage({ id: 'landing.colheader' })}
        </Header>

        <Container>
          <Box>
            <i
              className={`icon flaticon-chat`}
              role="presentation"
              aria-hidden="true"
            />
            <h2>{intl.formatMessage({ id: 'landing.col1.header' })}</h2>
            <h3>{intl.formatMessage({ id: 'landing.col1.subheader' })}</h3>
            <p className="content">
              {intl.formatMessage({ id: 'landing.col1.text' })}
            </p>
            <Button
              to={intl.formatMessage({ id: 'landing.col1.link' })}
              as={Link}
              color="primary"
              className="link"
            >
              {intl.formatMessage({ id: 'landing.col1.button' })}
            </Button>
          </Box>
          <Box>
            <i
              className={`icon flaticon-sound-waves-1`}
              role="presentation"
              aria-hidden="true"
            />
            <h2>{intl.formatMessage({ id: 'landing.col2.header' })}</h2>
            <h3>{intl.formatMessage({ id: 'landing.col2.subheader' })}</h3>
            <p className="content">
              {intl.formatMessage({ id: 'landing.col2.text' })}
            </p>
            <Button
              to={intl.formatMessage({ id: 'landing.col2.link' })}
              as={Link}
              color="primary"
              className="link"
            >
              {intl.formatMessage({ id: 'landing.col2.button' })}
            </Button>
          </Box>
          <Box>
            <i
              className={`icon flaticon-microphone`}
              role="presentation"
              aria-hidden="true"
            />
            <h2>{intl.formatMessage({ id: 'landing.col3.header' })}</h2>
            <h3>{intl.formatMessage({ id: 'landing.col3.subheader' })}</h3>
            <p className="content">
              {intl.formatMessage({ id: 'landing.col3.text' })}
            </p>
            <Button
              to={intl.formatMessage({ id: 'landing.col3.link' })}
              as={Link}
              color="primary"
              className="link"
            >
              {intl.formatMessage({ id: 'landing.col3.button' })}
            </Button>
          </Box>
          <Box>
            <i
              className={`icon flaticon-analytics`}
              role="presentation"
              aria-hidden="true"
            />
            <h2>{intl.formatMessage({ id: 'landing.col4.header' })}</h2>
            <h3>{intl.formatMessage({ id: 'landing.col4.subheader' })}</h3>
            <p className="content">
              {intl.formatMessage({ id: 'landing.col4.text' })}
            </p>
            <Button
              to={intl.formatMessage({ id: 'landing.col4.link' })}
              as={Link}
              color="primary"
              className="link"
            >
              {intl.formatMessage({ id: 'landing.col4.button' })}
            </Button>
          </Box>
        </Container>
      </div>

      <Testimonials data={data.cms.testimonials as any} />

      <Contact />
    </Layout>
  )
}

export const query = graphql`
  query Homepage($language: String) {
    site {
      siteMetadata {
        siteUrl
      }
    }

    cms {
      mainMenu: menus(
        where: { Position: "Main", Language: { locale: $language } }
      ) {
        ...MainMenuFragment
      }
      footerMenus: menus(
        where: { Position_ne: "Main", Language: { locale: $language } }
      ) {
        ...FooterMenusFragment
      }

      languages {
        ...LanguageSwitchFragment
      }

      testimonials: solutions(
        sort: "Priority"
        where: { Language: { locale: $language } }
      ) {
        ...TestimonialsFragment
      }
    }

    previewImage: file(relativePath: { eq: "home-preview.jpg" }) {
      childImageSharp {
        fixed(quality: 100, width: 1500, height: 1500) {
          ...GatsbyImageSharpFixed
        }
      }
    }
  }
`

export default IndexPage
